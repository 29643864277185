<template>
    <b-card-code no-body title="All banned words">
        <div class="mx-2 mb-2">
            <div class="mb-2">
                <b-button id="show-btn" @click="$bvModal.show('bv-default-words')">View default blacklisted words</b-button>
            </div>

            <!-- Table Top -->
            <b-row>
                <b-col>
                    <b-form-input v-model="word" placeholder="Ban new word"></b-form-input>
                </b-col>
                <b-col>
                    <b-button @click="banNewWord()" variant="primary" :disabled="isDisabled">Ban new word</b-button>
                </b-col>
            </b-row>         
            <div class="d-flex align-items-center justify-content-between mt-2 ">
                <!-- Per Page -->
                <div cols="6" md="6" >
                    <!-- page length -->
                    <b-form-group
                        label="Show"
                        label-cols="5"
                        label-align="left"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="text-nowrap mb-md-0 mr-1"
                    >
                        <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        id="input-23"
                        v-model="searchWord"
                        type="text"
                        size="sm"
                        placeholder="Search word"
                        required
                    />
                </div>
            </div>
        </div>

        <b-modal id="bv-default-words" hide-footer centered>
            
            <default-black-listed-words></default-black-listed-words>
            
        </b-modal>

        <b-modal
            id="update-word-modal"
            ref="update-word-modal"
            cancel-variant="outline-secondary"
            ok-title="Update"
            cancel-title="Close"
            centered
            title="Update word"
            @ok="updateWord"
            @cancel="getAllBannedWords"
        >
            <b-form>
                <b-form-group>
                    <label for="word">Word:</label>
                    <b-form-input
                        id="word"
                        type="text"
                        placeholder="Update word"
                        v-model="updateModalData.text"
                    />
                </b-form-group>
            </b-form>
        </b-modal>

        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="filteredList"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Text -->
            <template #cell(text)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.text }}
                </span>
            </template>

            <template #cell(actions)="data">
                <span>
                    <b-badge variant="light-primary" @click="showUpdateModal(data.item)" v-b-tooltip.hover.top="'Edit word'">
                    <feather-icon icon="EditIcon" />
                    </b-badge>
                </span>
                <span class="ml-1">
                    <b-badge variant="light-danger" @click="removeWordFromList(data.item.id)" v-b-tooltip.hover.top="'Remove word'">
                    <feather-icon icon="Trash2Icon" />
                    </b-badge>
                </span>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import DefaultBlackListedWords from "../../components/BlackListWords/DefaultBlackListedWords.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    VBTooltip,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BForm,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode,
        DefaultBlackListedWords
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            defaultBlacklistedWords: [],
            word: "",
            searchWord: "",
            disabled: true,
            updateModalData: {},
            data: [],
            perPage: 10,
            pageOptions: [5, 10, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: "text",
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "text", label: "Word", sortable: true },
                { key: "actions", label: "Actions", class: "text-right" },
            ],
        };
    },
    computed: {
        isDisabled() {
            if(this.word != "") {
                this.disabled = false
            } else {
                this.disabled = true
            }
            return this.disabled
        },
        filteredList() {
            let searchTerm = this.searchWord;
            let countWords = 0

            return this.data.filter(word => {
                if(word.text == null) {
                    word.text = ""
                }

                if(word.text.toLowerCase().includes(searchTerm.toLowerCase())) {
                    // Update total rows
                    countWords++
                    this.totalRows = countWords
                    
                    return word;
                }
            });
        }
    },
    methods: {
        getAllBannedWords() {
            // Return banned words list
            this.$http.get("/api/auth/admin/banned-words")
            .then((res) => {
                if(res != undefined) {
                    this.data = res.data

                    // Set the initial number of items
                    this.totalRows = this.data.length;
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            });
        },

        // Add new word to banned list
        banNewWord() {
            let bannedWord = {
                text: this.word.trim()
            }

            if(this.isDisabled == false) {
                this.$http.post("/api/auth/admin/banned-words", bannedWord)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The word was added to the blacklist!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });
                        // Reset field
                        this.word = ""

                        // Get fresh banned words list
                        this.getAllBannedWords();
                    }).catch((error) => {
                        let errorTitle = "There was an error adding the word to the blacklist!"

                        if(error.response.data.errors != undefined) {
                            errorTitle = error.response.data.errors.text[0]
                        }

                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorTitle,
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });

                        // Reset field
                        this.word = ""
                    });
            }
        },

        showUpdateModal(data) {
            this.updateModalData = data
            this.$refs['update-word-modal'].show()
        },

        updateWord() {

            if(this.updateModalData.text.trim() == "") {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: "The field word must not be empty",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                    },
                });
                this.getAllBannedWords();
                return;
            }

            // Request to update banned word
            this.$http.patch("/api/auth/admin/banned-words/" + this.updateModalData.id, {
                "text": this.updateModalData.text
            })
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                    title:
                    "Successfully updated blacklisted word!",
                        icon: "CheckSquareIcon",
                        variant: "success",
                    },
                });                
                this.getAllBannedWords();
            });            
        },

        removeWordFromList(word_id) {
            this.$bvModal
                .msgBoxConfirm('The word will be removed from the blacklist!', {
                title: 'Are your sure?',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {
                if(value == true) {
                    this.$http.delete("/api/auth/admin/banned-words/" + word_id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The word was successfully removed from the blacklist!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });
                        // Get fresh banned words list
                        this.getAllBannedWords();          
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error removing the word from the blacklist!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
    },
    mounted() {
        this.getAllBannedWords();
    }
}
</script>

<style scoped>

</style>