<template>
    <b-card-code no-body title="All default words">
        <div class="m-2">
       
            <div class="d-flex align-items-center justify-content-between mt-2 ">
                <!-- Per Page -->
                <div cols="6" md="6" >
                    <!-- page length -->
                    <b-form-group
                        label="Show"
                        label-cols="5"
                        label-align="left"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="text-nowrap mb-md-0 mr-1"
                    >
                        <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        inline
                        :options="pageOptions"
                        />
                    </b-form-group>
                </div>
                <div>
                    <b-form-input
                        style="max-width:250px"
                        id="input-23"
                        v-model="searchWord"
                        type="text"
                        size="sm"
                        placeholder="Search word"
                        required
                    />
                </div>
            </div>
        </div>

        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="filteredList"
            :fields="fields"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
            <!-- Column: Text -->
            <template #cell(text)="data">
                <span class="font-weight-bold d-block text-nowrap">
                    {{ data.item.text }}
                </span>
            </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
        </b-card-body>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
export default {
    components: {
        BRow,
        BCol,
        BTable,
        BAvatar,
        BBadge,
        BForm,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCardCode
    },
    data() {
        return {
            word: "",
            searchWord: "",
            disabled: true,
            updateModalData: {},
            data: [],
            perPage: 10,
            pageOptions: [5, 10, 20],
            totalRows: 1,
            currentPage: 1,
            sortDesc: false,
            sortDirection: "asc",
            fields: [
                { key: "text", label: "Word", sortable: true }
            ],
        };
    },
    computed: {
        filteredList() {
            let searchTerm = this.searchWord;
            let countWords = 0

            return this.data.filter(word => {
                if(word.text == null) {
                    word.text = ""
                }
                
                if(word.text.toLowerCase().includes(searchTerm.toLowerCase())) {
                    // Update total rows
                    countWords++
                    this.totalRows = countWords
                    
                    return word;
                }
            });     
        }
    },
    methods: {
        getDefaultBlacklistedWords() {
            // Return banned words list
            this.$http.get("/api/auth/admin/default-banned-words")
            .then((res) => {
                if(res != undefined) {
                    let defaultWords = []
                    for(let i=0; i<res.data.length; i++) {
                        defaultWords.push( { "text": res.data[i] } )
                    }
                    
                    this.data = [...defaultWords]
                    
                    // Set the initial number of items
                    this.totalRows = this.data.length;
                }
            })
            .catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            });
        }
    },
    mounted() {
        this.getDefaultBlacklistedWords();
    }
}
</script>

<style scoped>

</style>